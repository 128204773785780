export default [
    {
        path: "/pkpt/kematangan-mr",
        name: "kematanganMr",
        component: () => import("@/pages/pkpt/kematangan-mr/KematanganMr.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Kematangan MR",
            // icon: "icon-[tabler--users-group]",
            icon: "icon-[tabler--file-settings]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/dashboard",
        name: "pkpt",
        component: () => import("@/pages/pkpt/dashboard/Dashboard.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Dashboard",
            icon: "icon-[ic--outline-dashboard]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/audit-universe",
        name: "auditUniverse",
        component: () =>
            import("@/pages/pkpt/audit-universe/AuditUniverse.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Audit Universe",
            icon: "icon-[ph--file-magnifying-glass]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/evaluasi-rr",
        name: "evaluasiRr",
        component: () => import("@/pages/pkpt/evaluasi-rr/EvaluasiRr.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Evaluasi RR",
            icon: "icon-[tabler--checklist]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/auditable-tidak-masuk",
        name: "auditableTidakMasuk",
        component: () =>
            import(
                "@/pages/pkpt/auditable-tidak-masuk/AuditableTidakMasuk.vue"
            ),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Auditable Tidak Masuk",
            icon: "icon-[ic--outline-block]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/faktor-fraud",
        name: "faktorFraud",
        component: () => import("@/pages/pkpt/faktor-fraud/FaktorFraud.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Faktor Fraud",
            icon: "icon-[ph--shield-warning]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/faktor-isu-terkini",
        name: "faktorIsuTerkini",
        component: () =>
            import("@/pages/pkpt/faktor-isu-terkini/FaktorIsuTerkini.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Faktor Isu Terkini",
            icon: "icon-[mdi--newspaper-variant-outline]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/kk-faktor-anggaran",
        name: "kkFaktorAnggaran",
        component: () =>
            import("@/pages/pkpt/kk-faktor-anggaran/KkFaktorAnggaran.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "KK Faktor Anggaran",
            icon: "icon-[tabler--zoom-money]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/kk-faktor-unggulan",
        name: "kkFaktorUnggulan",
        component: () =>
            import("@/pages/pkpt/kk-faktor-unggulan/KkFaktorUnggulan.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "KK Faktor Unggulan",
            // icon: "icon-[tabler--users-group]",
            icon: "icon-[ph--folder-star]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/wajib",
        name: "wajib",
        component: () => import("@/pages/pkpt/wajib/Wajib.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Wajib (Mandatory)",
            // icon: "icon-[tabler--users-group]",
            icon: "icon-[tabler--alert-triangle]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/prioritas-pengawasan-sk",
        name: "prioritasPengawasanSk",
        component: () =>
            import(
                "@/pages/pkpt/prioritas-pengasawan-sk/PrioritasPengawasanSk.vue"
            ),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Prioritas Pengawasan SK",
            icon: "icon-[ph--shield-check]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/lampiran-pkpt",
        name: "lampiranPkpt",
        component: () => import("@/pages/pkpt/lampiran-pkpt/LampiranPkpt.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Lampiran PKPT",
            icon: "icon-[mdi--file-document-plus-outline]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/auditable-unit-desa",
        name: "auditableUnitDesa",
        component: () =>
            import("@/pages/pkpt/auditable-unit-desa/AuditableUnitDesa.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Auditable Unit Desa",
            icon: "icon-[tabler--map-pin-search]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/auditable-program",
        name: "auditableProgram",
        component: () =>
            import("@/pages/pkpt/auditable-program/AuditableProgram.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Auditable Program",
            icon: "icon-[ic--round-content-paste-search]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/auditable-unit-opd",
        name: "auditableUnitOpd",
        component: () =>
            import("@/pages/pkpt/auditable-unit-opd/AuditableUnitOpd.vue"),
        meta: {
            subApp: "PKPT",
            menu: "menu-pkpt",
            pageTitle: "Auditable Unit OPD",
            icon: "icon-[tabler--home-search]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
];
