const menu = [
    {
        name: "dashboard",
        icon: "icon-[ic--outline-dashboard]",
        title: "Dashboard",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "penugasan",
        icon: "icon-[ic--round-add-task]",
        title: "Penugasan",
        access: "pelaksana",
        type: "btn",
    },
    // {
    //     name: "laporanPenugasan",
    //     icon: "icon-[tabler--report]",
    //     title: "Laporan Penugasan",
    //     access: "pelaksana",
    //     type: "btn",
    // },
    // {
    //     name: "gallery",
    //     icon: "icon-[tabler--photo]",
    //     title: "Galeri",
    //     access: "guest",
    //     type: "btn",
    // },
    {
        icon: "icon-[tabler--clipboard-text]",
        title: "PKPT",
        access: "pelaksana",
        type: "dropdown",
        list: [
            {
                name: "auditUniverse",
                icon: "icon-[ph--file-magnifying-glass]",
                title: "Audit Universe",
                access: "pelaksana",
            },
            {
                name: "evaluasiRr",
                icon: "icon-[tabler--checklist]",
                title: "Evaluasi RR",
                access: "pelaksana",
            },
            {
                name: "kematanganMr",
                icon: "icon-[tabler--building-bank]",
                title: "Kematangan MR",
                access: "pelaksana",
            },
            {
                name: "kkFaktorUnggulan",
                icon: "icon-[ph--folder-star]",
                title: "KK Faktor Unggulan",
                access: "pelaksana",
            },
            {
                name: "faktorFraud",
                icon: "icon-[ph--shield-warning-bold]",
                title: "Faktor Temuan Fraud",
                access: "pelaksana",
            },
            {
                name: "faktorIsuTerkini",
                icon: "icon-[mdi--newspaper-variant-outline]",
                title: "Faktor Isu Terkini",
                access: "pelaksana",
            },
            {
                name: "auditableProgram",
                icon: "icon-[ic--round-content-paste-search]",
                title: "Auditable Prog",
                access: "pelaksana",
            },
            {
                name: "auditableUnitOpd",
                icon: "icon-[tabler--home-search]",
                title: "Auditable Unit OPD",
                access: "pelaksana",
            },
            {
                name: "auditableUnitDesa",
                icon: "icon-[tabler--map-pin-search]",
                title: "Auditable Unit Desa",
                access: "pelaksana",
            },
            {
                name: "prioritasPengawasanSk",
                icon: "icon-[ph--shield-check]",
                title: "Prioritas Pengawasan SK",
                access: "pelaksana",
            },
            {
                name: "wajib",
                icon: "icon-[tabler--alert-triangle]",
                title: "Wajib (Mandatory)",
                access: "pelaksana",
            },
            {
                name: "auditableTidakMasuk",
                icon: "icon-[ic--outline-block]",
                title: "Auditable yang Tidak Masuk",
                access: "pelaksana",
            },
            {
                name: "lampiranPkpt",
                icon: "icon-[mdi--file-document-plus-outline]",
                title: "Lampiran PKPT",
                access: "pelaksana",
            },
        ],
    },
    {
        icon: "icon-[tabler--database]",
        title: "Master",
        access: "pelaksana",
        type: "dropdown",
        list: [
            {
                name: "perencanaanStrategiPengawasan",
                icon: "icon-[tabler--user-up]",
                title: "Perencanaan Strategi Pengawasan",
                access: "pelaksana",
            },
            {
                name: "jenisPengawasan",
                icon: "icon-[tabler--user-up]",
                title: "Jenis Pengawasan",
                access: "pelaksana",
            },
            {
                name: "level",
                icon: "icon-[tabler--user-up]",
                title: "Level User",
                access: "pelaksana",
            },
            {
                name: "itban",
                icon: "icon-[ic--outline-work-outline]",
                title: "ITBAN",
                access: "pelaksana",
            },
            {
                name: "bagian",
                icon: "icon-[tabler--building-bank]",
                title: "Bagian",
                access: "pelaksana",
            },
            {
                name: "jabatan",
                icon: "icon-[tabler--user-shield]",
                title: "Jabatan",
                access: "pelaksana",
            },
            {
                name: "pegawai",
                icon: "icon-[tabler--users]",
                title: "Pegawai",
                access: "pelaksana",
            },
            {
                name: "opd",
                icon: "icon-[ph--buildings]",
                title: "OPD/Unit",
                access: "pelaksana",
            },
            {
                name: "hiperAktif",
                icon: "icon-[mdi--flash-alert-outline]",
                title: "Hiper Aktif",
                access: "pelaksana",
            },
            {
                name: "jenis",
                icon: "icon-[mdi--shape-outline]",
                title: "Jenis",
                access: "pelaksana",
            },
            {
                name: "kegiatan",
                icon: "icon-[ic--outline-directions-run]",
                title: "Kegiatan",
                access: "pelaksana",
            },
            {
                name: "resiko",
                icon: "icon-[ic--baseline-broken-image]",
                title: "Resiko",
                access: "pelaksana",
            },
            {
                name: "tingkatResiko",
                icon: "icon-[tabler--alert-hexagon]",
                title: "Tingkat Resiko",
                access: "pelaksana",
            },
            {
                name: "surat",
                icon: "icon-[tabler--mail]",
                title: "Surat",
                access: "pelaksana",
            },
            {
                name: "isuTerkini",
                icon: "icon-[tabler--news]",
                title: "Isu Terkini",
                access: "pelaksana",
            },
            {
                name: "anggaran",
                icon: "icon-[tabler--report-money]",
                title: "Anggaran",
                access: "pelaksana",
            },
        ],
    },
];

export default menu;
