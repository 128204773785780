export default [
    {
        path: "/not-authorized",
        name: "not-authorized",
        component: () => import("@/pages/error/NotAuthorized.vue"),
        meta: {
            pageTitle: "Tidak memiliki izin",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/404-not-found",
        name: "notFound",
        component: () => import("@/pages/error/Error.vue"),
        meta: {
            pageTitle: "Not found",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@/pages/auth/Login.vue"),
        meta: {
            pageTitle: "Login",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () => import("@/pages/auth/ForgotPassword.vue"),
        meta: {
            pageTitle: "Lupa password",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/pages/auth/ResetPassword.vue"),
        meta: {
            pageTitle: "Reset password",
            layout: "LayoutBlank",
            resource: "guest",
            action: "read",
        },
    },
];
