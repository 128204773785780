export default [
    {
        path: "/master/perencanaan-strategi-pengawasan",
        name: "perencanaanStrategiPengawasan",
        component: () => import("@/pages/master/perencanaan-strategi-pengawasan/PerencanaanStrategiPengawasan.vue"),
        meta: {
            pageTitle: "Perencanaan Startegi Pengawasan",
            // icon: "icon-[tabler--users-group]",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master/jenis-pengawasan",
        name: "jenisPengawasan",
        component: () => import("@/pages/master/jenis-pengawasan/JenisPengawasan.vue"),
        meta: {
            pageTitle: "Jenis Pengawasan",
            // icon: "icon-[tabler--users-group]",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/master/level",
        name: "level",
        component: () => import("@/pages/master/level/Level.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Level",
            icon: "icon-[tabler--user-up]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/bagian",
        name: "bagian",
        component: () => import("@/pages/master/bagian/Bagian.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Bagian",
            icon: "icon-[tabler--building]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/itban",
        name: "itban",
        component: () => import("@/pages/master/itban/Itban.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Itban",
            icon: "icon-[ic--outline-work-outline]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/jabatan",
        name: "jabatan",
        component: () => import("@/pages/master/jabatan/Jabatan.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Jabatan",
            icon: "icon-[tabler--user-shield]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/pegawai",
        name: "pegawai",
        component: () => import("@/pages/master/pegawai/Pegawai.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Pegawai",
            icon: "icon-[tabler--users]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/opd",
        name: "opd",
        component: () => import("@/pages/master/opd/Opd.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "OPD/Unit",
            icon: "icon-[ph--buildings]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/hiper-aktif",
        name: "hiperAktif",
        component: () => import("@/pages/master/hiper-aktif/HiperAktif.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Hiper Aktif",
            icon: "icon-[mdi--flash-alert-outline]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/jenis",
        name: "jenis",
        component: () => import("@/pages/master/jenis/Jenis.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Jenis",
            icon: "icon-[mdi--shape-outline]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/kegiatan",
        name: "kegiatan",
        component: () => import("@/pages/master/kegiatan/Kegiatan.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Kegiatan",
            icon: "icon-[ic--outline-directions-run]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/resiko",
        name: "resiko",
        component: () => import("@/pages/master/resiko/Resiko.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Resiko",
            icon: "icon-[ic--baseline-broken-image]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/anggaran",
        name: "anggaran",
        component: () => import("@/pages/master/anggaran/Anggaran.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Anggaran",
            icon: "icon-[tabler--report-money]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/surat",
        name: "surat",
        component: () => import("@/pages/master/surat/Surat.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Surat",
            icon: "icon-[tabler--mail]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/isu-terkini",
        name: "isuTerkini",
        component: () => import("@/pages/master/isu-terkini/IsuTerkini.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Isu Terkini",
            icon: "icon-[tabler--news]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
    {
        path: "/pkpt/master/tingkat-resiko",
        name: "tingkatResiko",
        component: () => import("@/pages/master/tingkat-resiko/TingkatResiko.vue"),
        meta: {
            subApp: "PKPT",
            menu: 'menu-pkpt',
            pageTitle: "Tingkat Manajemen Resiko",
            icon: "icon-[tabler--alert-hexagon]",
            layout: "LayoutAuth",
            resource: "pelaksana",
            action: "read",
        },
    },
];
