const menu = [
    {
        name: "sipengawas",
        icon: "icon-[ic--outline-dashboard]",
        title: "Dashboard",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "sipengawas.penugasan",
        icon: "icon-[ic--round-add-task]",
        title: "Penugasan",
        access: "pelaksana",
        type: "btn",
    },
];

export default menu;
