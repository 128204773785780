import ability from "@/plugins/casl/ability";
export default [
    {
        path: "/settings",
        name: "settings",
        component: () => import("@/pages/settings/Settings.vue"),
        meta: {
            pageTitle: "Pengaturan",
            icon: "icon-[ic--twotone-settings]",
            layout: "LayoutAuth",
            resource: "auth",
            action: "read",
        },
        children: [
            {
                path: "account",
                name: "accountSetting",
                component: () => import("@/pages/settings/AccountDialog.vue"),
                meta: {
                    resource: "auth",
                    action: "read",
                    savedPosition: true,
                },
            },
            {
                path: "biodata",
                name: "biodataSetting",
                component: () => import("@/pages/settings/BiodataDialog.vue"),
                meta: {
                    resource: "auth",
                    action: "read",
                    savedPosition: true,
                },
                beforeEnter: (to, from, next) => {
                    const canNavigate = () => {
                        return ability.can("read", "peserta") || ability.can("read", "pegawai");
                    };
                    if (!canNavigate()) {
                        return next("/not-authorized");
                    }
                    next();
                },
            },
            {
                path: "password",
                name: "passwordSetting",
                component: () => import("@/pages/settings/PasswordDialog.vue"),
                meta: {
                    resource: "auth",
                    action: "read",
                    savedPosition: true,
                },
            },
            {
                path: "avatar",
                name: "avatarSetting",
                component: () => import("@/pages/settings/AvatarDialog.vue"),
                meta: {
                    resource: "auth",
                    action: "read",
                    savedPosition: true,
                },
            },
            {
                path: "photo",
                name: "photoSetting",
                component: () => import("@/pages/settings/PhotoDialog.vue"),
                meta: {
                    resource: "all",
                    action: "read",
                    savedPosition: true,
                },
                beforeEnter: (to, from, next) => {
                    const canNavigate = () => {
                        return ability.can("read", "peserta");
                    };
                    if (!canNavigate()) {
                        return next("/not-authorized");
                    }
                    next();
                },
            },
        ],
    },
    {
        path: "/profile/:ulid",
        name: "profile",
        component: () => import("@/pages/profile/UserProfile.vue"),
        meta: {
            pageTitle: "Profil",
            icon: "icon-[ic--twotone-account-circle]",
            layout: "LayoutUser",
            resource: "peserta",
            action: "read",
        },
    },
    {
        path: "/profile/:ulid",
        name: "profile",
        component: () => import("@/pages/profile/UserProfile.vue"),
        meta: {
            pageTitle: "Profil",
            icon: "icon-[ic--twotone-account-circle]",
            layout: "LayoutUser",
            resource: "pegawai",
            action: "read",
        },
    },
];
