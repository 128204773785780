import { validate } from "uuid";

export default {
    VPage: {
        flat: true,
        color: "transparent",
        class: "boxed-container-content pb-10 px-2",
    },
    VIconSpin: {
        class: "group-hover:rotate-180 hover:rotate-180 duration-500 ease-out transition",
    },
    VCard: {
        flat: true,
        class: "drop-shadow-lg rounded-lg",
    },
    IconBtn: {
        icon: true,
        color: "default",
        variant: "text",
        VIcon: {
            size: 24,
        },
    },
    VAlert: {
        VBtn: {
            class: "tracking-wider",
            color: undefined,
        },
    },
    VAvatar: {
        // ℹ️ Remove after next release
        variant: "flat",
        VIcon: {
            size: 24,
        },
    },
    VBadge: {
        // set v-badge default color to primary
        color: "primary",
    },
    VBtn: {
        // set v-btn default color to primary
        // density: "default",
        rounded: 'lg',
        // color: "primary",
        class: "text-capitalize tracking-wider",
    },
    VChip: {
        elevation: 0,
    },
    VList: {
        color: "primary",
        class: "drop-shadow-lg",
    },
    VPagination: {
        color: "primary",
    },
    VTabs: {
        // set v-tabs default color to primary
        color: "primary",
        VSlideGroup: {
            showArrows: true,
        },
    },
    VTooltip: {
        // set v-tooltip default location to top
        location: "top",
    },
    VCheckboxBtn: {
        color: "primary",
        hideDetails: "auto",
    },
    VCheckbox: {
        // set v-checkbox default color to primary
        color: "primary",
        hideDetails: "auto",
    },
    VRadioGroup: {
        color: "primary",
        hideDetails: "auto",
    },
    VRadio: {
        color: "primary",
        hideDetails: "auto",
    },
    VSelect: {
        variant: "outlined",
        color: "primary",
        hideDetails: "auto",
        density: "comfortable",
    },
    VRangeSlider: {
        // set v-range-slider default color to primary
        color: "primary",
        density: "comfortable",
        thumbLabel: true,
        hideDetails: "auto",
    },
    VRating: {
        // set v-rating default color to primary
        color: "rgba(var(--v-theme-on-background),0.23)",
        color: "warning",
    },
    VSlider: {
        // set v-slider default color to primary
        color: "primary",
        hideDetails: "auto",
    },
    VTextField: {
        variant: "outlined",
        color: "primary",
        hideDetails: "auto",
        density: "comfortable",
    },
    VAutocomplete: {
        variant: "outlined",
        color: "primary",
        hideDetails: "auto",
        density: "comfortable",
    },
    VCombobox: {
        variant: "outlined",
        color: "primary",
        hideDetails: "auto",
        density: "comfortable",
    },
    VFileInput: {
        variant: "outlined",
        color: "primary",
        hideDetails: "auto",
        density: "comfortable",
    },
    VTextarea: {
        variant: "outlined",
        color: "primary",
        hideDetails: "auto",
    },
    VSwitch: {
        // set v-switch default color to primary
        color: "primary",
        hideDetails: "auto",
    },
    VDivider: {
        class: "my-2",
    },
    VForm: {
        validateOn : 'submit'
    }
};
