<template>
    <v-menu
        location="bottom right"
        :offset="$vuetify.display.mdAndUp ? 10 : 22"
        content-class="user-profile-menu-content"
        :close-on-content-click="closeMenu"
    >
        <template v-slot:activator="{ props }">
            <!-- <v-badge floating color="success" dot> -->
            <!-- :block="$vuetify.display.lgAndUp" -->
            <v-btn
                v-bind="props"
                :variant="
                    $vuetify.display.smAndUp || sidebar ? 'tonal' : 'text'
                "
                :icon="!$vuetify.display.mdAndUp"
                rounded="xl"
                height="fit-content"
                width="w-full"
                class="px-0 py-0 flex justify-start"
                :block="sidebar"
            >
                <v-avatar
                    size="45"
                    class="border-2 border-gray-100 border-solid"
                >
                    <img
                        :src="asset(authStore.user.avatar)"
                        @error="replaceAvaByDefault"
                    />
                </v-avatar>
                <div
                    v-if="$vuetify.display.mdAndUp || sidebar"
                    class="truncate mx-2 mr-4"
                >
                    {{ authStore.user.name }}
                </div>
            </v-btn>
            <!-- </v-badge> -->
        </template>
        <v-list rounded>
            <v-list-item>
                <template v-slot:prepend>
                    <v-avatar size="40" class="mr-2" @click="preventCloseMenu">
                        <img
                            v-viewer
                            :src="asset(authStore.user.avatar)"
                            @error="replaceAvaByDefault"
                        />
                    </v-avatar>
                </template>
                <v-list-item-title>
                    {{ authStore.user.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ authStore.user.level.name }}
                </v-list-item-subtitle>
            </v-list-item>
            <v-divider></v-divider>

            <!-- <v-list-item
                v-if="$can('read', 'peserta')"
                :disabled="$can('read', 'pending') && !$can('read', 'all')"
                :to="{ name: 'profile', params: { ulid: authStore.user.ulid } }"
            >
                <template v-slot:prepend>
                    <v-icon
                        size="23"
                        class="icon-[ic--twotone-account-circle]"
                    />
                </template>
                <v-list-item-title>Profil</v-list-item-title>
            </v-list-item> -->
            <v-list-item :to="{ name: 'settings' }">
                <template v-slot:prepend>
                    <v-icon size="22">
                        <span class="icon-[ic--twotone-settings]"></span>
                    </v-icon>
                </template>
                <v-list-item-title>Pengaturan</v-list-item-title>
            </v-list-item>
            <v-list-item @click="changeTheme" v-if="$vuetify.display.xs">
                <template v-slot:prepend>
                    <v-icon size="23">
                        <span :class="icon"></span>
                    </v-icon>
                </template>
                <v-list-item-title>Ubah tema</v-list-item-title>
            </v-list-item>
            <!-- <v-list-item @click="installAndroidOrIos" v-if="showInstallApp">
                <template v-slot:prepend>
                    <v-icon size="23">
                        <span class="icon-[tabler--download]"></span>
                    </v-icon>
                </template>
                <v-list-item-title>Install App</v-list-item-title>
            </v-list-item> -->

            <v-divider></v-divider>

            <div class="px-3 mt-4">
                <v-btn
                    :loading="loadingLogout"
                    block
                    rounded="5"
                    color="red"
                    @click="logOut"
                >
                    <v-icon start>
                        <span class="icon-[ic--twotone-log-out]"></span>
                    </v-icon>
                    Logout
                </v-btn>
            </div>
        </v-list>
    </v-menu>

    <HowToAddToHome ref="howToAddToHome" />
</template>
<script setup>
import { asset, replaceAvaByDefault } from "@/mixins";
import { useRouter } from "vue-router";
import ability from "@/plugins/casl/ability";
import { inject, ref, computed } from "vue";
import HowToAddToHome from "@/component/HowToAddToHome.vue";

import { useAuthStore } from "@/store/auth.js";
const authStore = useAuthStore();

const props = defineProps(["sidebar"]);

const isDark = inject("$isDark");
const banner = inject("$banner");

const router = useRouter();
const loadingLogout = ref();
const closeMenu = ref(true);

function updateAbility() {
    var rules = [{ action: "read", subject: "guest" }];
    ability.update(rules);
}

const standAlone = computed(() => {
    return window.matchMedia("(display-mode: standalone)").matches;
});
const showInstallApp = computed(() => {
    if (deferredPrompt.value != null || (authStore.isIos && !standAlone)) {
        return true;
    } else {
        return false;
    }
});

const howToAddToHome = ref(null);
let deferredPrompt = inject("$deferredPrompt");
function installAndroidOrIos() {
    if (authStore.isIos == false) {
        banner.value = true;
    } else {
        howToAddToHome.value.openDialog();
    }
}

const preventCloseMenu = () => {
    closeMenu.value = false;
    setTimeout(() => {
        closeMenu.value = true;
    }, 1000);
};

const logOut = () => {
    closeMenu.value = false;
    loadingLogout.value = true;
    axios.post("/logout").then(() => {
        loadingLogout.value = false;
        updateAbility();
        router.push({ name: "login" });
    });
};

import { useTheme } from "vuetify";
const theme = useTheme();
// console.log(theme.global.name.value);
const icon = computed(() => {
    return theme.global.current.value.dark
        ? "icon-[ic--round-light-mode]"
        : "icon-[ic--twotone-dark-mode]";
});

const changeTheme = () => {
    theme.global.name.value = theme.global.current.value.dark
        ? "light"
        : "dark";
    localStorage.setItem(
        "themeVariant",
        JSON.stringify(theme.global.name.value)
    );
    if (theme.global.name.value == "dark") {
        document.body.classList.add("dark");
    } else {
        document.body.classList.remove("dark");
    }
};
</script>

<style lang="scss">
.user-profile-menu-content {
    .v-list {
        min-width: 13rem !important;
    }
    .v-list-item {
        min-height: 2.5rem !important;
    }
}
</style>
