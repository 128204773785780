// import { DateTime } from "luxon";
import { dateTime } from "@/mixins";

const filters = {
    formatMoney(value) {
        return new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: "GBP",
        }).format(value);
    },
    formatIdr(value) {
        return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
        }).format(value);
    },
    toDate(value) {
        return dateTime(value).format("dddd, DD MMMM YYYY");
    },
    toDateTime(value) {
        return dateTime(value).format("dddd, DD MMMM YYYY, HH:mm");
    },
    toTime(value) {
        return DateTime.fromSQL(value)
            .setLocale("id")
            .toLocaleString(DateTime.TIME_24_SIMPLE);
    },
    gender(value) {
        if (value == "L") {
            return "Laki-laki";
        } else {
            return "Perempuan";
        }
    },
};

// Vue.filter('fulldate', function(value){
//     return moment(value).format('dddd, DD MMMM YYYY')
// });

export default filters;
