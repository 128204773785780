<template>
    <LayoutAuth v-if="route.meta.layout == 'LayoutAuth'" />
    <LayoutHome v-if="route.meta.layout == 'LayoutHome'" />
    <LayoutBlank v-if="route.meta.layout == 'LayoutBlank'" />
</template>

<script setup>
import { useRoute } from "vue-router";
// import LayoutFull from "./layout/LayoutFull.vue";
import LayoutAuth from "./layout/LayoutAuth.vue";
import LayoutHome from "./layout/LayoutHome.vue";
import LayoutBlank from "./layout/LayoutBlank.vue";

const route = useRoute();
</script>
