<template>
    <div class="boxed-navbar-home px-5">
        <div
            class="flex align-center cursor-pointer"
            @click="$router.push({ name: 'home' })"
        >
            <v-avatar rounded="lg" size="43">
                <v-img :src="asset('/android-chrome-512x512.png')"></v-img>
            </v-avatar>
            <!-- v-if="$vuetify.display.width > 393" -->
            <div class="ml-3" v-if="$vuetify.display.width > 350">
                <div class="font-bold text-xl whitespace-nowrap">Look At</div>
                <!-- <div class="text-[9pt] sm:text-sm -mt-1">Kab. Bogor</div> -->
            </div>
        </div>
        <v-spacer></v-spacer>
        <ThemeSwitcher class="mr-2" variant="text" />
        <v-slide-y-reverse-transition>
            <v-btn icon rounded="full" variant="text" class="mr-3">
                <v-icon
                    class="icon-[ic--baseline-notifications-active]"
                ></v-icon>
            </v-btn>
        </v-slide-y-reverse-transition>
        <AppBarUserMenu></AppBarUserMenu>
    </div>
</template>
<script setup>
// import menu from "@/menu.js";
import ThemeSwitcher from "./component/ThemeSwitcher.vue";
import HorizontalMenu from "./component/HorizontalMenu.vue";
import AppBarUserMenu from "./component/AppBarUserMenu.vue";
import { asset } from "@/mixins";
import { ref, watch } from "vue";

const props = defineProps(["drawer", "menu", "countMenu"]);
const emit = defineEmits(["emitDrawer"]);
const openDrawer = () => {
    emit("emitDrawer");
    key.value += 1;
};

const key = ref(false);
watch(
    () => props.drawer,
    () => {
        key.value += 1;
    }
);
</script>
