const menu = [
    {
        name: "pkpt",
        icon: "icon-[ic--outline-dashboard]",
        title: "Dashboard",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "auditUniverse",
        icon: "icon-[ph--file-magnifying-glass]",
        title: "Audit Universe",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "evaluasiRr",
        icon: "icon-[tabler--checklist]",
        title: "Evaluasi RR",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "kematanganMr",
        icon: "icon-[tabler--file-settings]",
        title: "Kematangan MR",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "kkFaktorAnggaran",
        icon: "icon-[tabler--zoom-money]",
        title: "KK Faktor Anggaran",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "kkFaktorUnggulan",
        icon: "icon-[ph--folder-star]",
        title: "KK Faktor Unggulan",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "faktorFraud",
        icon: "icon-[ph--shield-warning-bold]",
        title: "Faktor Temuan Fraud",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "faktorIsuTerkini",
        icon: "icon-[mdi--newspaper-variant-outline]",
        title: "Faktor Isu Terkini",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "auditableProgram",
        icon: "icon-[ic--round-content-paste-search]",
        title: "Auditable Program",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "auditableUnitOpd",
        icon: "icon-[tabler--home-search]",
        title: "Auditable Unit OPD",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "auditableUnitDesa",
        icon: "icon-[tabler--map-pin-search]",
        title: "Auditable Unit Desa",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "prioritasPengawasanSk",
        icon: "icon-[ph--shield-check]",
        title: "Prioritas Pengawasan SK",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "wajib",
        icon: "icon-[tabler--alert-triangle]",
        title: "Wajib (Mandatory)",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "auditableTidakMasuk",
        icon: "icon-[ic--outline-block]",
        title: "Auditable yang Tidak Masuk",
        access: "pelaksana",
        type: "btn",
    },
    {
        name: "lampiranPkpt",
        icon: "icon-[mdi--file-document-plus-outline]",
        title: "Lampiran PKPT",
        access: "pelaksana",
        type: "btn",
    },
    {
        icon: "icon-[tabler--database]",
        title: "Master",
        access: "pelaksana",
        type: "dropdown",
        list: [
            {
                name: "level",
                icon: "icon-[tabler--user-up]",
                title: "Level User",
                access: "pelaksana",
            },
            {
                name: "itban",
                icon: "icon-[ic--outline-work-outline]",
                title: "ITBAN",
                access: "pelaksana",
            },
            {
                name: "bagian",
                icon: "icon-[tabler--building-bank]",
                title: "Bagian",
                access: "pelaksana",
            },
            {
                name: "jabatan",
                icon: "icon-[tabler--user-shield]",
                title: "Jabatan",
                access: "pelaksana",
            },
            {
                name: "pegawai",
                icon: "icon-[tabler--users]",
                title: "Pegawai",
                access: "pelaksana",
            },
            {
                name: "opd",
                icon: "icon-[ph--buildings]",
                title: "OPD/Unit",
                access: "pelaksana",
            },
            {
                name: "hiperAktif",
                icon: "icon-[mdi--flash-alert-outline]",
                title: "Hiper Aktif",
                access: "pelaksana",
            },
            {
                name: "jenis",
                icon: "icon-[mdi--shape-outline]",
                title: "Jenis",
                access: "pelaksana",
            },
            {
                name: "kegiatan",
                icon: "icon-[ic--outline-directions-run]",
                title: "Kegiatan",
                access: "pelaksana",
            },
            {
                name: "resiko",
                icon: "icon-[ic--baseline-broken-image]",
                title: "Resiko",
                access: "pelaksana",
            },
            {
                name: "tingkatResiko",
                icon: "icon-[tabler--alert-hexagon]",
                title: "Tingkat Resiko",
                access: "pelaksana",
            },
            {
                name: "surat",
                icon: "icon-[tabler--mail]",
                title: "Surat",
                access: "pelaksana",
            },
            {
                name: "isuTerkini",
                icon: "icon-[tabler--news]",
                title: "Isu Terkini",
                access: "pelaksana",
            },
            {
                name: "anggaran",
                icon: "icon-[tabler--report-money]",
                title: "Anggaran",
                access: "pelaksana",
            },
        ],
    },
];

export default menu;
